<template>
  <div>
    <v-app-bar class="bg-prosk-secondary">
      <v-row class="container-prosker m-auto py-0 my-0">
        <v-col :md="currentUser ? 3 : 2" cols="6" class="d-flex align-items-center">
            <router-link :to="{ name: currentUser && currentUser.type_user === $options.USER_TYPE_CLIENT ? 'social.clients-page' : 'social.proskers-page' }">
              <img src="../../../assets/images/logo/logo-header.svg" class="img-fluid" width="190" alt="logo" style="margin-left: -6px"/>
            </router-link>
        </v-col>
        <v-col :md="6" class="d-none d-md-flex align-items-center">
          <v-text-field
            :placeholder="this.$t('searchHere') + ' ...'"
            v-model="search"
            outlined
            background-color="white"
            dense
            clearable
            class="custom-placeholder-color search-bar"
            color="#000"
            hide-details
            @keyup.enter.native="sendGlobalSearch"
          >
            <template v-slot:append>
              <v-icon color="#3F44A6"> mdi-magnify </v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col :md="currentUser ? 3 : 4" cols="6" class="d-flex justify-content-end align-items-center">
          <v-menu v-if="currentUser" offset-y :close-on-content-click="showMenu" location="bottom">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-avatar>
                  <img
                    v-if="!currentUser.profile_img"
                    src="@/assets/images/page-img/avatar.png"
                    class="rounded-circle avatar-50"
                    alt="user"
                  />
                  <img
                    v-else
                    :src="currentUser.profile_img"
                    class="rounded-circle avatar-50"
                    alt="user"
                  />
                </v-avatar>
              </v-btn>
            </template>
            <v-card class="mx-auto" max-width="300" tile>
              <v-list
                dense
                flat
              >
                <v-list-item-group>
                  <v-list-item
                    v-show="currentUser && currentUser.type_user === $options.USER_TYPE_CLIENT"
                    v-for="(item, i) in currentMenu"
                    :key="i"
                    :to="{ name: item.path, params: item.params }"
                    exact-path
                    @click="showMenu = true"
                  >
                    <v-list-item-content>
                      <span class="secondary-prosk">
                        {{ $t(item.title) }}
                      </span>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon small class="secondary-prosk">{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-group
                    v-show="currentUser && !(currentUser.type_user === $options.USER_TYPE_CLIENT)"
                    :value="true"
                    class="secondary-prosk"
                  >
                    <template v-slot:activator>
                      <v-list-item-content @click="showMenu = false" class="secondary-prosk">
                        {{ $t("office") }}
                      </v-list-item-content>
                    </template>
                    <v-list-item
                      v-for="item in currentMenu"
                      :key="item.title"
                      @click="showMenu = true"
                    >
                      <v-list-item-content>
                        <router-link
                          :to="{ name: item.path, params: item.params, query: item.query }"
                          exact-path
                          class="secondary-prosk"
                        >
                          {{ $t(item.title) }}
                        </router-link>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon small class="secondary-prosk">{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list-group>
                  <v-list-group v-show="purchaseMenu.length">
                    <template v-slot:activator>
                      <v-list-item-content @click="showMenu = false" class="secondary-prosk">
                        {{ $t("purchases") }}
                      </v-list-item-content>
                    </template>
                    <v-list-item
                      v-for="item in purchaseMenu"
                      :key="item.title"
                      @click="showMenu = true"
                    >
                      <v-list-item-content>
                        <router-link
                          :to="{ name: item.path, params: item.params }"
                          class="secondary-prosk"
                        >
                          {{ $t(item.title) }}
                        </router-link>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon small class="secondary-prosk">{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list-group>
                  <v-list-group v-if="(currentUser && currentUser.type_user) === $options.USER_TYPE_ADMIN">
                    <template v-slot:activator>
                      <v-list-item-content @click="showMenu = false" class="secondary-prosk">
                        {{ $t("admin") }}
                      </v-list-item-content>
                    </template>
                    <v-list-item
                      v-for="item in menuAdmin"
                      :key="item.title"
                      @click="showMenu = true"
                    >
                      <v-list-item-content>
                        <router-link
                          :to="{ name: item.path }"
                          class="secondary-prosk"
                        >
                          {{ $t(item.title) }}
                        </router-link>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon small class="secondary-prosk">{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list-group>
                  <v-divider />
                  <v-list-item @click="signOut">
                    <v-list-item-content>
                      <span class="secondary-prosk">
                        {{ $t("signOut") }}
                      </span>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon small class="secondary-prosk"
                        >mdi-logout</v-icon
                      >
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
          <div v-if="!currentUser" class="d-md-flex d-none align-items-center justify-content-end fill-height">
            <router-link class="white--text caption px-3 text-center" :to="{ name: 'social.subscription-page' }">
              {{ $t('joinAsProsker')}}
            </router-link>
            <router-link text small class="white--text caption px-3 text-center" :to="{ name: 'auth1.sign-up1' }">{{ $t('signUp')}}
            </router-link>
            <router-link text small class="white--text caption px-3 text-center" :to="{ name: 'auth1.sign-in1' }">{{ $t('logIn')}}
            </router-link>
          </div>
          <div v-if="!currentUser" class="d-md-none d-flex align-items-center justify-content-end fill-height">
            <v-menu  offset-y location="bottom" :close-on-click="true">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon color="white">mdi-menu</v-icon>
                </v-btn>
              </template>
              <v-card class="mx-auto" tile>
                <v-list dense flat>
                  <v-list-item-group>
                    <v-list-item>
                      <router-link class="secondary-prosk d-flex align-items-center" text :to="{ name: 'social.subscription-page' }">
                        {{ $t('joinAsProsker')}}
                      </router-link>
                    </v-list-item>
                    <v-list-item>
                      <router-link text class="secondary-prosk" :to="{ name: 'auth1.sign-up1' }">
                        {{ $t('signUp') }}
                      </router-link>
                    </v-list-item>
                    <v-list-item>
                      <router-link text class="secondary-prosk" :to="{ name: 'auth1.sign-in1' }">
                        {{ $t('logIn') }}
                      </router-link>
                    </v-list-item>
                    <v-list-item>
                      <router-link text class="secondary-prosk" :to="{ name: 'social.categories-page' }">
                        {{ $t('categories') }}
                      </router-link>
                    </v-list-item>
                    <v-list-item>
                      <router-link text class="secondary-prosk" :to="{ name: 'social.users',
                      params: { query: JSON.stringify(globalSearchObject) } }">
                        {{ $t('searchProsker') }}
                      </router-link>
                    </v-list-item>
                    <v-list-item>
                      <router-link text class="secondary-prosk" :to="{ name: 'social.contact-page' }">
                        {{ $t('help') }}
                      </router-link>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-col md="12" class="text-center d-md-none d-block">
      <v-form @submit.prevent="sendGlobalSearch">
        <v-text-field
          :label="this.$t('searchHere') + ' ...'"
          v-model="search"
          outlined
          clearable
          dense
          color="teal"
          background-color="white"
          hide-details
          @keyup.enter.native="sendGlobalSearch"
        >
          <template v-slot:append>
            <v-icon color="#01ab91" @click="sendGlobalSearch"> mdi-magnify </v-icon>
          </template>
        </v-text-field>
      </v-form>
    </v-col>
    <v-toolbar class="d-none d-md-block bg-green-prosk bottom-nav w-100  container-prosker" elevation="0">
      <v-main class="container-prosker">
        <v-toolbar-items>
          <v-btn
            :value="item.value"
            text
            active-class="bg-color rounded-pill secondary-prosk margin"
            class="secondary-prosk rounded-pill"
            v-for="item in menuLeft"
            :key="item.title"
            :to="item.value === 3 ?
            { path: '/perfiles', query: { search: getSearchText } } : item.path">
            <span class="py-2 text-body-1 text-capitalize">{{$t(item.title) }}</span>
          </v-btn>
        </v-toolbar-items>
      </v-main>
    </v-toolbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import CryptoJS from 'crypto-js';
import { USER_TYPE_CLIENT, USER_TYPE_ADMIN, USER_TYPE_PROSKER, FEATURE_JOBS, FEATURE_SUBSCRIPTIONS,
  HOME_ROUTE, CALENDAR_TAB, AES_SECRET } from '@/misc/constants';
import { isFeatureEnabled } from '@/misc/featureFlagService';

export default {
  name: 'NavBar',
  components: {
  },
  props: {
    image: {
      type: String,
      default: require('@/assets/images/logo/favicon.svg')
    }
  },
  data () {
    return {
      showMenu: false,
      search: '',
      globalSearchObject: {},
      globalUserObject: {},
      currentMenu: [],
      purchaseMenu: [],
      hashedId: '',
      menuLeft: [
        { title: 'home', path: '/', value: 1 },
        { title: 'categories', path: '/categorias', value: 2 },
        { title: 'searchProsker', value: 3 },
        { title: 'favorites', path: '/mis-favoritos', value: 4 },
        { title: 'contact', path: '/contacto', value: 5 }
      ],
      menuAdmin: [
        {
          title: 'adminUsers',
          path: 'social.users-information',
          icon: 'mdi-script',
          enabled: (this.currentUser && this.currentUser.type_user === this.$options.USER_TYPE_ADMIN)
        },
        {
          title: 'adminCategories',
          path: 'social.categories',
          icon: 'mdi-playlist-star',
          enabled: (this.currentUser && this.currentUser.type_user === this.$options.USER_TYPE_ADMIN)
        },
        { title: 'adminSkills',
          path: 'social.skills',
          icon: 'mdi-script',
          enabled: (this.currentUser && this.currentUser.type_user === this.$options.USER_TYPE_ADMIN)
        },
        { title: 'adminServices',
          path: 'social.jobs-admin',
          icon: 'mdi-script',
          enabled: (this.currentUser && this.currentUser.type_user === this.$options.USER_TYPE_ADMIN)
        },
        { title: 'adminCancellationReason',
          path: 'social.cancellation-reason',
          icon: 'mdi-script',
          enabled: (this.currentUser && this.currentUser.type_user === this.$options.USER_TYPE_ADMIN)
        }
      ]
    };
  },
  watch: {
    getSearchText: function (value) {
      if (value) {
        this.setSearch();
      }
    },
      search: function (value) {
        if (!value) {
            this.globalSearchObject.query = null;
            this.sendGlobalSearch();
        }
      }
  },
  created () {
    this.setImportedConstants();
    this.hashedId = CryptoJS.AES.encrypt(this.currentUser.id.toString(), this.$options.AES_SECRET).toString();
    this.currentMenu = (this.currentUser && this.currentUser.type_user === this.$options.USER_TYPE_CLIENT) ? this.getCustomerMenu() : this.getProskerMenu();
    this.currentMenu = this.currentMenu.filter(item => item.enabled);
    this.purchaseMenu = (this.currentUser && this.currentUser.type_user === this.$options.USER_TYPE_CLIENT) ? [] : this.getPurchaseMenu();
    this.purchaseMenu = this.purchaseMenu.filter(item => item.enabled);
    this.setSearchTextFromUrl();
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),
    getSearchText () {
      return this.$store.getters['search/searchText'];
    },
    currentRouteName () {
        return this.$route.name;
    }
  },
  methods: {
    getProskerMenu () {
      return [
        { title: 'home', path: 'social.list', icon: 'mdi-home', enabled: true },
        {
          title: 'myAccount',
          path: 'social.user-profile',
          params: { query: `${this.currentUser.username}-${this.hashedId}` },
          icon: 'mdi-account',
          enabled: true
        },
        {
          title: 'myJobs',
          path: 'social.services',
          params: { query: 'mis-servicios' },
          icon: 'mdi-hammer-screwdriver',
          enabled: this.$options.JOBS_ENABLED
        },
        { title: 'messages', path: 'app.chat', params: { conversationId: '0' }, icon: 'mdi-chat', enabled: true },
        { title: 'calendar',
          path: 'social.user-profile',
          params: { query: `${this.currentUser.username}-${this.hashedId}` },
          query: { selectedTab: this.$options.CALENDAR_TAB },
          icon: 'mdi-calendar',
          enabled: this.$options.USER_TYPE_CLIENT || (this.currentUser.active_subscription.filter(item => item.name === 'Premium' || item.name === 'Free Trial').length > 0)
        },
        {
          title: 'subscription',
          path: 'social.user-subscription',
          icon: 'mdi-cash',
          enabled: this.$options.SUBSCRIPTIONS_ENABLED
        },
        { title: 'contact', path: 'social.contact-page', icon: 'mdi-email', enabled: true }
      ];
    },
    getPurchaseMenu () {
      return [
        {
          title: 'servicesMenu',
          path: 'social.services',
          params: { query: 'servicios-contratados' },
          icon: 'mdi-tools',
          enabled: this.$options.JOBS_ENABLED
        },
        { title: 'favorites', path: 'social.favorites', icon: 'mdi-heart', enabled: true },
        {
          title: 'categories',
          path: 'social.categories-page',
          icon: 'mdi-waves',
          enabled: true
        }
      ]
    },
    getCustomerMenu () {
      return [
        { title: 'home', path: 'social.list', icon: 'mdi-home', enabled: true },
        {
          title: 'myAccount',
          path: 'social.user-profile',
          params: { query: `${this.currentUser.username}-${this.hashedId}` },
          icon: 'mdi-account',
          enabled: true
        },
        {
          title: 'categories',
          path: 'social.categories-page',
          icon: 'mdi-waves',
          enabled: true
        },
        { title: 'messages', path: 'app.chat', params: { conversationId: '0' }, icon: 'mdi-chat', enabled: true },
        { title: 'favorites', path: 'social.favorites', icon: 'mdi-heart', enabled: true },
        { title: 'contact', path: 'social.contact-page', icon: 'mdi-email', enabled: true },
        { title: 'services', path: 'social.user-services', icon: 'mdi-tools', enabled: this.$options.JOBS_ENABLED }
      ];
    },
    setSearchTextFromUrl () {
      if (this.$route.query.search) {
        this.updateSearchTextAction(this.$route.query.search);
      }
    },
    setSearch () {
      this.search = this.getSearchText;
    },
    setImportedConstants () {
      this.$options.AES_SECRET = AES_SECRET;
      this.$options.USER_TYPE_CLIENT = USER_TYPE_CLIENT;
      this.$options.CALENDAR_TAB = CALENDAR_TAB;
      this.$options.USER_TYPE_ADMIN = USER_TYPE_ADMIN;
      this.$options.USER_TYPE_PROSKER = USER_TYPE_PROSKER;
      this.$options.HOME_ROUTE = HOME_ROUTE;
      this.$options.SUBSCRIPTIONS_ENABLED = isFeatureEnabled(process.env.VUE_APP_RELEASE_VERSION, FEATURE_SUBSCRIPTIONS);
      this.$options.JOBS_ENABLED = isFeatureEnabled(process.env.VUE_APP_RELEASE_VERSION, FEATURE_JOBS);
    },
    sendGlobalSearch () {
        this.updateSearchTextAction(this.search);
        this.$router.push({ path: '/perfiles', query: { search: this.getSearchText } })
    },
    ...mapActions({
      signOutActions: 'auth/signOut',
      updateSearchTextAction: 'search/updateSearchText'
    }),
    signOut () {
      this.signOutActions().then(() => {
        this.$router.push({ name: 'auth1.sign-in1' });
      });
    }
  }
};
</script>
<style scoped>
  .search-bar {
    border-radius: 100px;
  }

  .w-100 {
    width: 100%;
  }

  .bg-color {
    background-color: #ffffff;
  }

  .bottom-nav {
/*    border-radius: 100px;*/
    width: 70%;
/*    margin: 24px auto 0 auto;*/
  }

  .margin {
    margin: 0 24px;
  }
</style>
